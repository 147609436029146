var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"banner"},[_c('warning-tip',{attrs:{"code":"SPTSSPPJ_V2"}}),_c('main-top',{attrs:{"title":"商品评价","desc":"在线查询天猫、淘宝商品宝贝类目评价。","spend":"SPTSSPPJ_V2"}},[_c('InputView',{attrs:{"extractProductId":true,"CanYouClick":_vm.CanYouClick,"placeholder-name":"请输入您的宝贝链接或宝贝ID"},on:{"sendSearchName":_vm.getSearchName}})],1),_c('bordure-row',{staticStyle:{"margin-bottom":"30px"},attrs:{"title":"评价信息","shadow":""}},[_c('div',[_c('div',{staticClass:"control-tool"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.downloadExcel}},[_vm._v("一键导出")])],1),_c('el-table',{staticStyle:{"width":"100%","border":"1px solid #f4f4f4"},attrs:{"data":_vm.goodsFeedList,"size":"mini","header-cell-style":{
          background: '#f8f8f8',
          'font-family': 'PingFangSC-Regular',
          color: '#666666 !important',
          'letter-spacing': '0',
          'text-align': 'center',
          'font-weight': 'normal',
        }}},[_c('el-table-column',{attrs:{"align":"center","prop":"nick","label":"买家昵称","width":"100"}}),_c('el-table-column',{attrs:{"align":"center","prop":"title","label":"评价内容","width":"200"}}),_c('el-table-column',{attrs:{"align":"center","prop":"sku","label":"SKU"}}),_c('el-table-column',{attrs:{"align":"center","prop":"rate","label":"评分"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('el-image',{staticClass:"li-icon",attrs:{"src":row.starPic}})]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"pics","label":"图片"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [(row.pics && row.pics.length > 0)?_c('el-image',{staticClass:"li-pic",attrs:{"src":row.pics[0],"fit":"contain","preview-src-list":row.pics}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"created","label":"日期","width":"130"}})],1),_c('el-pagination',{staticClass:"pagination",attrs:{"current-page":_vm.currentPage,"page-size":20,"layout":"total, prev, pager, next,jumper","total":_vm.total},on:{"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)]),_c('Introduce',{attrs:{"introduce":_vm.introduce}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }