<template>
  <div>
    <div class="banner">
      <warning-tip code="SPTSSPPJ_V2"></warning-tip>

      <main-top title="商品评价" desc="在线查询天猫、淘宝商品宝贝类目评价。" spend="SPTSSPPJ_V2">
        <!-- 使用搜索框组件 -->
        <InputView :extractProductId="true" :CanYouClick="CanYouClick" 
            placeholder-name="请输入您的宝贝链接或宝贝ID"
            @sendSearchName="getSearchName"></InputView>
      </main-top>

      <bordure-row title="评价信息" shadow style="margin-bottom: 30px">
        <!-- 以下部位为表格 -->
        <div>
          <div class="control-tool">
            <el-button type="primary" size="small" @click="downloadExcel"
              >一键导出</el-button
            >
          </div>

          <el-table :data="goodsFeedList" style="width: 100%; border: 1px solid #f4f4f4" size="mini" :header-cell-style="{
            background: '#f8f8f8',
            'font-family': 'PingFangSC-Regular',
            color: '#666666 !important',
            'letter-spacing': '0',
            'text-align': 'center',
            'font-weight': 'normal',
          }">
            
            <el-table-column align="center" prop="nick" label="买家昵称" width="100">
            </el-table-column>
            
            <el-table-column align="center" prop="title" label="评价内容" width="200"></el-table-column>

            <el-table-column align="center" prop="sku" label="SKU"></el-table-column>

            <el-table-column align="center" prop="rate" label="评分">
              <template v-slot="{ row }">
                <!-- {{ row.rate }} -->
                <el-image class="li-icon" :src="row.starPic"></el-image>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="pics" label="图片">
              <template v-slot="{ row }">
                <el-image class="li-pic" :src="row.pics[0]" v-if="row.pics && row.pics.length > 0" fit="contain"
                  :preview-src-list="row.pics">
                </el-image>
              </template>

            </el-table-column>


            <el-table-column align="center" prop="created" label="日期" width="130"></el-table-column>


          </el-table>
          <!-- 以下部分为分页器 -->
          <el-pagination  class="pagination" @current-change="handleCurrentChange"
            :current-page.sync="currentPage" :page-size="20" layout="total, prev, pager, next,jumper" :total="total">
          </el-pagination>
        </div>
      </bordure-row>

      <!-- 讲解文案区域 -->
      <Introduce :introduce="introduce"></Introduce>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { getGoodsFeedListV2, goodsSkuCount } from "../../request/api"; //这里是引入请求
import InputView from "../public/InputView";
import Introduce from "../public/Introduce";
export default {
  data() {
    return {
      value: "",
      CanYouClick: true, //搜索框是否允许点击
      goodsFeedList: [
        {
          title: "锤没选择好，锤头不够尖",
          nick: "t**2",
          sku: "颜色分类:单个不锈钢肉锤",
          created: "2021-03-29",
          rate: "1",
          starPic: "https://img.alicdn.com/tfs/TB1_40SzpY7gK0jSZKzXXaikpXa-447-78.png",
          pics: ["//img.alicdn.com/imgextra/i1/0/O1CN01TWRY7723s1uUnQs5R_!!0-rate.jpg"]
        },
        {
          title: "锤没选择好，锤头不够尖",
          nick: "t**2",
          sku: "颜色分类:单个不锈钢肉锤",
          created: "2021-03-29",
          rate: "1",
          starPic: "https://img.alicdn.com/tfs/TB1_40SzpY7gK0jSZKzXXaikpXa-447-78.png",
          pics: ["//img.alicdn.com/imgextra/i1/0/O1CN01TWRY7723s1uUnQs5R_!!0-rate.jpg"]
        },
      ], //竞品评价表格数据

      currentPage: 1, //当前第几页
      total: 5, //表格总条数
      introduce: [
        {
          title: "功能介绍",
          icon: "&#xe60a;",
          content: [
            "快速查找商家的评价，可以查找买家对商品的差评，以便我们快速更改，避免问题再次出现",
          ],
        },
      ], //介绍相关数组
    };
  },
  components: {
    InputView,
    Introduce,
  },
  methods: {
    // 点击底部分页器时
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getGoodsFeedListFn(this.value);
    },
    // 点击搜索时的方法
    getSearchName(value) {
      this.value = value;
      this.$store.commit("alterJumpFlag", true);
      this.$store.commit(
        "alterLoadContent",
        "正在获取商品评价及竞品评价sku的数据"
      );
      this.CanYouClick = false; //不允许再点击搜索
      this.getGoodsFeedListFn(value); //请求商品评价的数据
    },
    // 请求商品评价的数据
    getGoodsFeedListFn(value) {
      this.goodsFeedList = [];
      getGoodsFeedListV2({
        itemid: value, // "596446991976"
        page: this.currentPage, //不知道是什么参数
      })
        .then((data) => {
          let res = data.data || {};
          this.goodsFeedList = res.data || [];
          this.total = Number(res.allCount);
        })
        .finally(() => {
          this.$store.commit("alterJumpFlag", false);
          this.CanYouClick = true; //允许再次搜索
        });
    },
    downloadExcel() {
      this.$confirm("确定下载列表文件?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.excelData = this.goodsFeedList;
          this.export2Excel();
        })
        .catch(() => {});
    },
    export2Excel() {
      var that = this;
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/export2Excel"); // 这里必须使用绝对路径，使用@/+存放export2Excel的路径
        // 导出的表头名信息
        const tHeader = [
          "买家昵称",
          "评价内容",
          "SKU",
          "评分",
          "图片",
          "日期",
        ];
        // 导出的表头字段名，需要导出表格字段名
        const filterVal = [
          "nick",
          "title",
          "sku",
          "starPic",
          "pics",
          "created"
        ];

        let vue = this;
        const list = that.excelData;
        let data = list.map(function (item) {
          return filterVal.map(function (path) {
            let newVal = _.get(item, path);
            return newVal;
          });
        });
        let curDate = moment().format("YYYY-MM-DD");
        export_json_to_excel(tHeader, data, "商品评价-" + curDate); // 导出的表格名称，根据需要自己命名
      });
    },

  },
};
</script>

<style lang="less" scoped>
.bannerContent {
  display: flex;
}


.img_floor {
  width: 100px;
  height: 100px;
  background: linear-gradient(0deg, #ceefff, #7bd5ff);
  margin-right: 50px;
}

.li-icon {
  width: auto;
  height: 24px;
}

.li-pic {
  max-width: 160px;
  width: auto;
  height: 80px;
}

.control-tool {
  padding: 4px;
  text-align: right;
}

</style>